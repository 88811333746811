@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .masonry-4-col {
      column-count: 4;
      column-gap: 1em;
    }
    .masonry-3-col {
      column-count: 3;
      column-gap: 1em;
    }
    .masonry-2-col {
      column-count: 2;
      column-gap: 1em;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
